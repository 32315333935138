import config from '../../views/config/config'
import { axiosFunc, AppenData } from '../common'
import { Decryptdata, Encryptdata } from '../encryptkeys';
var token;

export const GetNftCookieToken = () => {
    token = document.cookie.split('; ').reduce((r, v) => {
        const parts = v.split('=')
        console.log('egfwafwer', parts)
        return parts[0] === "token" ? decodeURIComponent(parts[1]) : r
    }, '');
}

//NFT Name Validation Function
export const nftNameValidation = async (data) => {
    var senddata = {
        method: 'post',
        url: `${config.BACK_URL}/nft/ValidateTokenName`,
        data: { data: Encryptdata(data) },
        'headers': {
            "Content-Type": 'application/json',
            'Authorization': token
        }
    }
    console.log('jsgfds', senddata)
    let Resp = await axiosFunc(senddata)
    Resp.data = Decryptdata(Resp.data)
    return Resp.data
}
//nftCreate axios
export const nftCreate = async (data) => {
    console.log("UserRegister is Calling", data)
    var formdata = AppenData(data)
    var senddata = {
        method: 'post',
        url: `${config.BACK_URL}/nft/nftcreate`,
        data: formdata[0],
        'headers': {
            'Content-Type': 'multipart/form-data',
            'Authorization': token
        }
    }

    let Resp = await axiosFunc(senddata)
    Resp.data = Decryptdata(Resp.data)

    return Resp.data
}

export const MintAndPruchase = async (data) => {
    console.log("UserRegister is Calling", data)
    //var formdata = AppenData(data)
    var senddata = {
        method: 'post',
        url: `${config.BACK_URL}/nft/MintAndPurchase`,
        data: { data: Encryptdata(data) },
        'headers': {
            'Content-Type': 'multipart/form-data',
            'Authorization': token
        }
    }

    let Resp = await axiosFunc(senddata)
    Resp.data = Decryptdata(Resp.data)

    return Resp.data
}

//Nft Image Upload Function
export const NFTImageUpload = async (data) => {
    var formdata = AppenData(data)
    var senddata = {
        method: 'post',
        url: `${config.BACK_URL}/nft/nftimageupload`,
        data: formdata[0],
        'headers': {
            'Content-Type': 'multipart/form-data',
            'Authorization': token
        }
    }
    let Resp = await axiosFunc(senddata)
    Resp.data = Decryptdata(Resp.data)


    return Resp.data
}

export const MintMetaData = async (data) => {
   // var formdata = AppenData(data)
    var senddata = {
        method: 'post',
        url: `${config.BACK_URL}/nft/generateNFTMetadata`,
        data: { data: Encryptdata(data) },
        'headers': {
            'Content-Type': 'multipart/form-data',
            'Authorization': token
        }
    }
    let Resp = await axiosFunc(senddata)
    Resp.data = Decryptdata(Resp.data)
    return Resp.data
}

// Create New NFT
export const CreateNFT = async (data) => {
    if (data.from == 'othercollection') {
        var senddata = {
            method: 'post',
            url: `${config.BACK_URL}/nft/listcollectionnft`,
            data: { data: Encryptdata(data) },
            'headers': {

                "Content-Type": 'application/json',

                'Authorization': token
            }
        }
        let Resp = await axiosFunc(senddata)
        Resp.data = Decryptdata(Resp.data)

        return Resp.data
    }
    else {
        var senddata = {
            method: 'post',
            url: `${config.BACK_URL}/nft/createnft`,
            data: { data: Encryptdata(data) },
            'headers': {
                "Content-Type": 'application/json',

                'Authorization': token
            }
        }
        let Resp = await axiosFunc(senddata)
        Resp.data = Decryptdata(Resp.data)

        return Resp.data
    }
}

// Get Category Function
export const Category = async (data) => {

    var senddata = {
        method: 'GET',
        url: `${config.BACK_URL}/nft/getcategory`,
    }
    let Resp = await axiosFunc(senddata)
    Resp.data = Decryptdata(Resp.data)

    return Resp.data
}

//GET All NFT
export const Token_List_Func = async (data) => {
    var senddata = {
        method: 'GET',
        url: `${config.BACK_URL}/nft/Tokenlistfunc`,
        params: data
    }
    let Resp = await axiosFunc(senddata)
    Resp.data = Decryptdata(Resp.data)

    return Resp.data
}

//Get NFT info
export const Token_Info_Func = async (data) => {
    var senddata = {
        method: 'GET',
        url: `${config.BACK_URL}/nft/info`,
        params: data
    }
    let Resp = await axiosFunc(senddata)
    Resp.data = Decryptdata(Resp.data)

    return Resp.data
}

//Buy And Accept 
export const BuyAccept = async (data) => {
    var senddata = {
        method: 'post',
        url: `${config.BACK_URL}/nft/BuyAccept`,
        data: { data: Encryptdata(data) },
        'headers': {
            "Content-Type": 'application/json',

            'Authorization': token
        }
    }
    let Resp = await axiosFunc(senddata)
    Resp.data = Decryptdata(Resp.data)

    return Resp.data
}

//put on Sale
export const CreateOrder = async (data) => {
    var senddata = {
        method: 'post',
        url: `${config.BACK_URL}/nft/CreateOrder`,
        data: { data: Encryptdata(data) },
        'headers': {
            "Content-Type": 'application/json',

            'Authorization': token
        }

    }
    let Resp = await axiosFunc(senddata)
    Resp.data = Decryptdata(Resp.data)

    return Resp.data
}

//Bid Function
export const BidApprove = async (FormValue) => {
    console.log('====================================');
    console.log("APPROVECALLLTOKEN", token);
    console.log('====================================');
    await GetNftCookieToken();
    var senddata = {
        method: 'post',
        url: `${config.BACK_URL}/nft/BidAction`,
        data: { data: Encryptdata(FormValue) },
        'headers': {
            "Content-Type": 'application/json',

            'Authorization': token
        }
    }
    let Resp = await axiosFunc(senddata)
    Resp.data = Decryptdata(Resp.data)

    return Resp.data
}

export const TopCreatorApi = async () => {
    var senddata = {
        method: 'get',
        url: `${config.BACK_URL}/nft/TopCreatorApi`
    }
    let Resp = await axiosFunc(senddata)
    Resp.data = Decryptdata(Resp.data)

    return Resp.data
}

export const HotAuctionHotSales = async () => {
    var senddata = {
        method: 'get',
        url: `${config.BACK_URL}/nft/HotAuctionHotSale`
    }
    let Resp = await axiosFunc(senddata)
    Resp.data = Decryptdata(Resp.data)

    return Resp.data
}

export const CreateCollectionFunc = async (data) => {
    var formdata = AppenData(data)
    var senddata = {
        method: 'post',
        url: `${config.BACK_URL}/nft/CreateCollectionFunc`,
        data: formdata[0],
        'headers': {
            'Content-Type': 'multipart/form-data',
        }
    }
    let Resp = await axiosFunc(senddata)
    Resp.data = Decryptdata(Resp.data)


    return Resp.data
}

export const getnfttaglist = async (data) => {

    var senddata = {
        method: 'GET',
        url: `${config.BACK_URL}/user/getnfttag`,
    }
    let Resp = await axiosFunc(senddata)
    console.log("nfttt", Resp);
    Resp.data = Decryptdata(Resp.data)

    return Resp.data
}
export const CollectionByCreator = async (data) => {
    var senddata = {
        method: 'get',
        url: `${config.BACK_URL}/nft/CollectionByCreator`,
        params: data ?? {}
    }
    let Resp = await axiosFunc(senddata)
    Resp.data = Decryptdata(Resp.data)

    return Resp.data
}
export const Activity_List_Func = async (data) => {
    var senddata = {
        method: 'get',
        url: `${config.BACK_URL}/nft/Activity_List_Func`,
        params: data ?? {}
    }
    let Resp = await axiosFunc(senddata)
    Resp.data = Decryptdata(Resp.data)

    return Resp.data
}
export const CollectionBySymbol = async (data) => {
    var senddata = {
        method: 'get',
        url: `${config.BACK_URL}/nft/CollectionBySymbol`,
        params: data ?? {}
    }
    let Resp = await axiosFunc(senddata)
    Resp.data = Decryptdata(Resp.data)

    return Resp.data
}

export const Getpromotedtoken = async () => {
    var senddata = {
        method: 'get',
        url: `${config.BACK_URL}/nft/getpromotedtoken`,
    }
    let Resp = await axiosFunc(senddata)
    Resp.data = Decryptdata(Resp.data)

    return Resp.data
}

export const TopNftCreatorsNfts = async (data) => {
    var senddata = {
        method: 'get',
        url: `${config.BACK_URL}/nft/TopNftCreatorsNfts`,
        params: data
    }
    let Resp = await axiosFunc(senddata)
    Resp.data = Decryptdata(Resp.data)

    return Resp.data
}


export const EditCollectionbyCreator = async (data) => {
    var formdata = AppenData(data)
    var senddata = {
        method: 'post',
        url: `${config.BACK_URL}/nft/editcollectionbycreator`,
        data: formdata[0],
        'headers': {
            'Content-Type': 'multipart/form-data',
        }
    }
    let Resp = await axiosFunc(senddata)
    Resp.data = Decryptdata(Resp.data)

    return Resp.data
}

export const GetNFTOwner = async (data) => {
    var senddata = {
        method: 'post',
        url: `${config.BACK_URL}/nft/getnftowner`,
        'headers': {
            "Content-Type": 'application/json',
        },
        data: { data: Encryptdata(data) }
    }
    let Resp = await axiosFunc(senddata)
    Resp.data = Decryptdata(Resp.data)

    return Resp.data
}

export const NftbalanceUpdate = async (data) => {
    var senddata = {
        method: 'POST',
        url: `${config.BACK_URL}/nft/findupdatebalance`,
        'headers': {
            "Content-Type": 'application/json',
        },
        data: { data: Encryptdata(data) }
    }
    let Resp = await axiosFunc(senddata)
    Resp.data = Decryptdata(Resp.data)


    return Resp.data
}

export const UpdateScrectKey = async (data) => {
    var senddata = {
        method: 'POST',
        url: `${config.BACK_URL}/nft/findAndUpdateScrectKey`,
        'headers': {
            "Content-Type": 'application/json',
        },
        data: { data: Encryptdata(data) }
    }
    let Resp = await axiosFunc(senddata)
    Resp.data = Decryptdata(Resp.data)


    return Resp.data
}
