import * as anchor from '@project-serum/anchor';
import Config from '../config/config'
import {
    Connection,
    LAMPORTS_PER_SOL,
    Transaction,
    clusterApiUrl,
    PublicKey,
    Keypair,
} from "@solana/web3.js";
import {
    PhantomWalletAdapter,
    SolflareWalletAdapter,
    MathWalletAdapter,
    CoinbaseWalletAdapter
} from '@solana/wallet-adapter-wallets';
import {
    SolanaMobileWalletAdapter,
    createDefaultAuthorizationResultCache,
    createDefaultAddressSelector,
    createDefaultWalletNotFoundHandler,
} from "@solana-mobile/wallet-adapter-mobile";
import axios from 'axios';
import { WalletConnectWalletAdapter } from '@solana/wallet-adapter-walletconnect';
import {
    getAssociatedTokenAddress,
    createAssociatedTokenAccountInstruction,
    ASSOCIATED_TOKEN_PROGRAM_ID,
    TOKEN_PROGRAM_ID,
    createApproveInstruction,
    getAssociatedTokenAddressSync
  } from "@solana/spl-token";
  import idl from "../../Abi/idl.json"
  import { Decryptdata, Encryptdata } from "../../actions/encryptkeys"
import { config } from 'process';
const { SystemProgram } = anchor.web3;

var provider;
var programInstance;
function isMobileOrTablet() {
    return /(android|iphone|ipad|mobile)/i.test(navigator.userAgent);
}
export const connectWallet = async (data) => {
    var accountDetails = {}
    const network = clusterApiUrl('devnet');
    try {
        let walletAdapter;
        if (data === "math") {
            if (isMobileOrTablet()) {

                /* Mobile Walletconncet */
                walletAdapter = new WalletConnectWalletAdapter({
                    network,
                    options: {
                        relayUrl: 'wss://relay.walletconnect.com',
                        projectId: 'b8a1daa2dd22335a2fe1d2e139980ae0',
                        metadata: {
                            name: 'Example App', // App Name
                            description: 'Example App', // App Description
                            url: 'https://200.140.70.236:3001/walcon', // App Frontend URL
                            icons: ['https://avatars.githubusercontent.com/u/35608259?s=200'], // App Logo
                        },
                    },
                })
            } else {
                /* Math wallet Extension */
                walletAdapter = new MathWalletAdapter({ network })
            }
        }
        if (data === "mobile") {
            /* Solana Mobile wallet Adapter (panthom , solfare) */
            walletAdapter = new SolanaMobileWalletAdapter({
                addressSelector: createDefaultAddressSelector(),
                appIdentity: {
                    name: 'My app', //App Name
                    uri: 'https://200.140.70.236:3001/walcon', //App
                    icon: 'public/logo512.png',
                },
                authorizationResultCache: createDefaultAuthorizationResultCache(),
                cluster: 'mainnet-beta',
                onWalletNotFound: createDefaultWalletNotFoundHandler(),
            });
        }
        if (data === "phantom") {
            /* Panthom wallet adapter */
            walletAdapter = new PhantomWalletAdapter()
        }
        if (data === "solfare") {
            /* Solfare wallet adapter */
            walletAdapter = new SolflareWalletAdapter()
        }
        if (data === "coinbase") {
            /* coinbase wallet adapter */
            walletAdapter = new CoinbaseWalletAdapter()
        }
        
        /* Wallet connections */
        await walletAdapter.connect();
        const connection = new Connection(clusterApiUrl("devnet"), "confirmed"); // mainnet-beta
        let bal = await connection.getBalance(walletAdapter.publicKey)
        let Tolkenbal = await getTokenbalance(Config.erc20Address,walletAdapter.publicKey)
        provider = walletAdapter
        console.log("walletAdapter",walletAdapter,(bal / LAMPORTS_PER_SOL),Tolkenbal,Config.erc20Address);
        localStorage.setItem("accountInfo", walletAdapter?.publicKey.toString())
        localStorage.setItem('walletConnectType', data)
        accountDetails.accountAddress = walletAdapter?.publicKey.toString()
                accountDetails.web3p = walletAdapter;
                accountDetails.coinBalance = (bal / LAMPORTS_PER_SOL)
                accountDetails.web3 = walletAdapter;
                accountDetails.tokenBalance = Tolkenbal?.tokenBalance ? Tolkenbal?.tokenBalance  : 0;
                accountDetails.decimals = Tolkenbal?.Decimal ? Tolkenbal?.Decimal : 0 ;
        return accountDetails
        // { walletAdapter, Address: walletAdapter?.publicKey.toString(), Sol: (bal / LAMPORTS_PER_SOL) }
    } catch (error) {
        console.log("erreeeereeeee",error);
        if (data === "math") {
            let walletAdapter = new MathWalletAdapter({ network })
            if (walletAdapter.readyState === 'NotDetected') {
                window.alert("Math Wallet not Detected , make sure panthom is not exist")
            } else {
                window.alert("Make Sure youre Wallet was unlocked")
            }
            return {}
        }
    }
}

export const getTokenbalance = async (tokenAddress,walletAddress) => {
  let tokenBalance = 0;
  let Decimal = 0;
  const response = await axios({
    url: `https://api.devnet.solana.com/`, // devnet URL or mainnet URL
    method: "post",
    headers: { "Content-Type": "application/json" },
    data: {
      jsonrpc: "2.0",
      id: 1,
      method: "getTokenAccountsByOwner",
      params: [
        walletAddress, // account addrss
        {
          mint: tokenAddress, // token mint address
        },
        {
          encoding: "jsonParsed",
        },
      ],
    },
  });
  console.log("~ getTokenbalance ~ response:", response);
  if (
    Array.isArray(response?.data?.result?.value) &&
    response?.data?.result?.value?.length > 0 &&
    response?.data?.result?.value[0]?.account?.data?.parsed?.info?.tokenAmount
      ?.amount > 0
  ) {
    tokenBalance = Number(
      response?.data?.result?.value?.length > 0 &&
        response?.data?.result?.value[0]?.account?.data?.parsed?.info
          ?.tokenAmount?.uiAmount
    );
    Decimal =
      response?.data?.result?.value[0]?.account?.data?.parsed?.info
        ?.tokenAmount?.decimals;
  }
  return { tokenBalance, Decimal };
};

export const getServiceFees = async (Address) => {
  // var rpcObj = new Web3(Config.RPC_URL)
  // var fees = {}
  // if (rpcObj) {
  //   try {
  //     var marketObj = new rpcObj.eth.Contract(
  //       marketAbi,
  //       Config.TradeContract
  //     );
  //     var alreadylist = await marketObj.methods.whitLisrUsers(Address).call();
  //     var servicefees = await marketObj.methods.getServiceFee().call()
  //     var contractobj = await marketObj.methods.NativeAddress().call()
  //     console.log("alreadylist", alreadylist, servicefees);
  //     if (alreadylist) {
  //       fees.buyerFees = '0'
  //       fees.sellerFees = '0'
  //       fees.buyerFeesNative = '0'
  //       fees.sellerFeesNative = '0'
  //       fees.NativeToken = ""
  //     } else {
  //       fees.buyerFees = servicefees[0]
  //       fees.sellerFees = servicefees[1]
  //       fees.buyerFeesNative = servicefees[2]
  //       fees.sellerFeesNative = servicefees[3]
  //       fees.NativeToken = contractobj
  //     }

  //     return fees;
  //   }
  //   catch (e) {
  //     console.log("service fees catch blok running", e)
  //   }
  // }
  var fees = {}
  let program = await getProgramInstance(idl);
  const accountData = await program.account.accountData.fetch(idl.owner.address);
  console.log("accountDataaccountDataaccountData",accountData);
  fees.buyerFees = Number(accountData.buyerFee) / 100
  fees.sellerFees = Number(accountData.sellerFee) / 100
  return fees
}

const arrayBufferToString = (buf) => {
    let uint8Array = new Uint8Array(buf);
    return btoa(String.fromCharCode(...uint8Array));
  };

  const stringToArrayBuffer = (string) => {
    const binaryString = atob(string);
    return new Uint8Array([...binaryString].map((char) => char.charCodeAt(0)));
  };

  const getTransactionstatus = async (tx) => {
    const connection = new Connection(clusterApiUrl("devnet"), "confirmed");
    const result = await connection.getSignatureStatus(tx, {
      searchTransactionHistory: true,
    });
    console.log("🚀 ~ getTransactionstatus ~ result:", result);
    return result?.value?.confirmationStatus === "confirmed";
  };

  const tokenApprove = async (
    address,
    approvalAddress,
    tokenAdd,
    amount,
    decimal,
    provider
  ) => {
    try {
      console.log("!decimal ?  Number(amount) : new anchor.BN(amount * 10 ** Number(decimal))",!decimal ?  Number(amount) : new anchor.BN(Number(amount) * 10 ** Number(decimal)),decimal);
      const value = !decimal ?  Number(amount) : new anchor.BN(Number(amount) * 10 ** Number(decimal)); // assuming token has 9 decimal places
      console.log("valuevaluevalue",value);
      const connection = new Connection(clusterApiUrl("devnet"), "confirmed");
      const walletPublicKey = new PublicKey(address); // from account or Owner of token
      const delegatePublicKey = new PublicKey(approvalAddress); // to account or recipiant of token
      const associatedMintTokenAddress = await getAssociatedTokenAddress(
        new PublicKey(tokenAdd),
        new PublicKey(address)
      );
      //  await createATA(
      //   address,
      //   tokenAdd,
      //   address
      // ); // associated token acoount from owner
      console.log("associatedMintTokenAddress-->", associatedMintTokenAddress);
      const transaction = new Transaction().add(
        createApproveInstruction(
          new PublicKey(associatedMintTokenAddress),
          delegatePublicKey,
          walletPublicKey,
          value,
          [],
          TOKEN_PROGRAM_ID
        )
      );
      const AdminGas = stringToArrayBuffer(Config.PRIVATE);
      const  AdminPayer = Keypair.fromSecretKey(Buffer.from(AdminGas));
      console.log("AdminPayer",AdminPayer);
      transaction.feePayer = AdminPayer.publicKey
     
      //new PublicKey(provider.publicKey.toString());
      const { blockhash } = await connection.getLatestBlockhash();
      transaction.recentBlockhash = blockhash;
      console.log("blockhash-->", blockhash);
      transaction.sign(AdminPayer);
      const signed = await provider.signTransaction(transaction);
      console.log("signed-->", signed);
      const signature = await connection.sendRawTransaction(signed.serialize());
      console.log("signature-->", signature);
      if (signature) {
        console.log(
          "TransactionHash",
          `https://explorer.solana.com/tx/${signature}?cluster=devnet`
        );
      }
      let signhash = await connection.confirmTransaction(signature);
      console.log("signhash-->", signhash);
      let status = await getTransactionstatus(signature);
      return { status };
    } catch (error) {
      console.log("~ tokenApprove ~ error:", error);
      return { status: false };
    }
  };
 export const nftDelegateApprove = async (Details) => {
    const delegateAddress = Keypair.generate();
    console.log(
      "🚀 ~ nftDelegateApprove ~ delegateAddress:",
      delegateAddress.publicKey.toString()
    );
    const mintAddress = new PublicKey(Details.NFTId);
    

    let string = arrayBufferToString(delegateAddress.secretKey.buffer);
    console.log("string", string);

    let arraybuffer = stringToArrayBuffer(string);
    console.log("arraybuffer:", arraybuffer);

    const seller = Details.NFTOwner;
    const nftMint = mintAddress;
    console.log("🚀 ~ nftDelegateApprove ~ nftMint:", nftMint);
    /* Token Approve */
    const tokenapprove = await tokenApprove(
      seller.toString(),
      delegateAddress.publicKey.toString(),
      nftMint.toString(), // mint Token Address
      1, // amount of Token,
      null,
      Details.provider
    );
    const Data ={
      ScrectKey:string,
      tokenapprove:tokenapprove
    }
    return Data;
  };

  const createATA = async (ADD, tokenadd, signer,provider) => {
    console.log(
      "🚀 ~ createATA ~ ADD, tokenadd, signer:",
      ADD,
      tokenadd,
      signer
    );
    return new Promise(async (resolve, reject) => {
      try {
        const connection = new Connection(
          "https://api.devnet.solana.com",
          "confirmed"
        );
        const publicKey = new PublicKey(ADD);
        const mintAddress = new PublicKey(tokenadd); // TOKEN ADDRESS

        // Calculate the associated token account address
        const ata = await getAssociatedTokenAddress(
          mintAddress,
          publicKey,
          false, // Allow owner off curve
          TOKEN_PROGRAM_ID, //new PublicKey('TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA'),
          ASSOCIATED_TOKEN_PROGRAM_ID
        );
        console.log("ata-->", ata.toBase58());
        // Check if the associated token account already exists
        const ataInfo = await connection.getAccountInfo(ata);
        console.log("ataInfo-->", ataInfo);
        if (ataInfo) {
          console.log(
            `Associated token account already exists: ${ata.toBase58()}`
          );
          // return ata.toBase58();
          return resolve(ata.toBase58());
        }

        // Create the associated token account
        const transaction = new Transaction().add(
          createAssociatedTokenAccountInstruction(
            new PublicKey(signer), // payer (connected walllet address)
            ata, // associated token account
            publicKey, // token account owner
            mintAddress // token mint
            // TOKEN_PROGRAM_ID,//new PublicKey('TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA'),
            // ASSOCIATED_TOKEN_PROGRAM_ID
          )
        );
        console.log("transaction-->", transaction);
        // Sign and send the transaction (assuming you have the public key's private key)
        // Here, replace `YOUR_PRIVATE_KEY` with the actual private key of the public key
        const AdminGas = stringToArrayBuffer(Config.PRIVATE);
        const  AdminPayer = Keypair.fromSecretKey(Buffer.from(AdminGas));
        console.log("AdminPayer",AdminPayer);
        transaction.feePayer = 
        AdminPayer.publicKey
        // transaction.feePayer = 
        //new PublicKey(signer);
        const { blockhash } = await connection.getLatestBlockhash();
        transaction.recentBlockhash = blockhash;
        console.log("blockhash-->", blockhash);
        transaction.sign(AdminPayer);
        const signed = await provider.signTransaction(transaction);
        console.log("signed-->", signed);
        const signature = await connection.sendRawTransaction(
          signed.serialize()
        );
        console.log("signature-->", signature);
        let signhash = await connection.confirmTransaction(signature);
        console.log("signhash-->", signhash);
        // return ataInfo
        resolve(ata.toBase58());
      } catch (error) {
        console.error("Error creating associated token account:", error);
        reject(error);
        return null;
      }
    });
  };

  const getProgramInstance = async (jsonfile) => {
    try{
    const connection = new Connection(clusterApiUrl("devnet"), "confirmed");
    const anchorProvider = new anchor.AnchorProvider(connection, provider, {
      preflightCommitment: "confirmed",
    });
    anchor.setProvider(anchorProvider);
    console.log("🚀 ~ getProgramInstance ~ provider:", provider);
    const programID = new PublicKey(
      jsonfile ? jsonfile.metadata.address : jsonfile.metadata.address
    );
    const anchorProgram = new anchor.Program(
      jsonfile,
      programID,
      anchorProvider
    );
    programInstance = anchorProgram;
    return anchorProgram;
    }catch(err){
      console.log("~ getProgramInstance ~ err:", err);
    }
  };

  export const buyNFT = async (delegateKeypair,mintAddress,sellerAddress,Newbuyer,provider,Amount,MintPubKey,fee,NFTQuantity) => {
    console.log(`Newbuyer`,delegateKeypair,mintAddress,sellerAddress,Newbuyer,provider,Amount,MintPubKey,fee);
    try {
      const nftmetatdataaddress1 = new PublicKey(MintPubKey)
      const delegateKeypair1 = stringToArrayBuffer(delegateKeypair);
      const  delegateAddress = Keypair.fromSecretKey(Buffer.from(delegateKeypair1));
      console.log(
        "buyNFT  delegateAddress:",
        delegateAddress,
        delegateAddress.publicKey.toString()
      );
      const nftMint = new PublicKey(mintAddress);
      // new PublicKey(
      //   "BEJ6dDnNr2v6STWr7Kj12zDpg5xmoMbKa3PJVovaTwr1"
      // );
      console.log("buyNFT  nftMint:", nftMint);
      const price = new anchor.BN(Amount * LAMPORTS_PER_SOL);
      console.log("buyNFT  price:", price);
      const count = new anchor.BN(Number(NFTQuantity))
      const seller = new PublicKey(sellerAddress); //sellerAddress;
      // new PublicKey(
      //   "7oBWNCMkUZTsJRXC79sRSCoYHRUeRwvnk6y6Et5pKvTx"
      // );
      const feeAmt = Number(Amount) * Number(fee) /100
      const totalprice = new anchor.BN((Number(Amount) + Number(feeAmt))* LAMPORTS_PER_SOL )
      console.log("buyNFT  seller:", seller,totalprice);
      const buyer =
        // new PublicKey("Dvi3sSGzjiHbTBh5vCDLH1dfLjgX199KwCsvBKfcHgD")
        Newbuyer;
      console.log("buyNFT  buyer:", buyer);
      let program = await getProgramInstance(idl);
      console.log("buyNFT  program:", program);
      const connection = new Connection(clusterApiUrl("devnet"), "confirmed");

      const sellerATA = await getAssociatedTokenAddress(nftMint, seller);
      // await createATA(
      //   seller.toString(),
      //   nftMint.toString(),
      //   provider.publicKey.toString()
      // );

      // const accountInfo = await connection.getParsedAccountInfo(sellerATA)
      // await connection.getAccountInfo(sellerATA);
      // console.log("" buyNFT  accountInfo:", accountInfo)

      console.log("buyNFT  sellerATA:", sellerATA);

      const buyerATA = 
      // await getAssociatedTokenAddress(nftMint, buyer);
      await createATA(
        buyer.toString(),
        nftMint.toString(),
        Newbuyer.toString(),
        provider
      );

      const accountData = await program.account.accountData.fetch(idl.owner.address);
      console.log("🚀 ~ buyNFT ~ accountData:", accountData)
      const ownerPubkey = accountData.owner;

      const metaData = await program.account.nftMetadata.fetch(nftmetatdataaddress1)
      console.log("🚀 ~ buyNFT ~ metaData:", metaData)
      const creator = metaData.creator;

      console.log("buyNFT  buyerATA:", buyerATA);
      const AdminGas = stringToArrayBuffer(Config.PRIVATE);
      const  AdminPayer = Keypair.fromSecretKey(Buffer.from(AdminGas));
      let transaction = await program.methods
        .buyNft(price,totalprice, count)
        .accounts({
          payer:AdminPayer.publicKey.toString(),
          buyer: buyer,
          seller: seller,
          sellerNftAccount: sellerATA, // Replace with the seller's NFT token account
          buyerNftAccount:new PublicKey(buyerATA), // buyerATA, // Replace with the buyer's NFT token account
          delegate: delegateAddress.publicKey, // Replace with the delegate authority's public key
          tokenProgram: TOKEN_PROGRAM_ID,
          systemProgram: SystemProgram.programId,
          metaAccount: nftmetatdataaddress1,
          ownerAccount: new PublicKey(idl.owner.address),
          owner : ownerPubkey,
          creator : creator
        })
        .transaction();
      console.log("transaction1111-,->", transaction,Config.PRIVATE,{
        payer:AdminPayer.publicKey.toString(),
        buyer: buyer,
        seller: seller,
        sellerNftAccount: sellerATA, // Replace with the seller's NFT token account
        buyerNftAccount:new PublicKey(buyerATA), // buyerATA, // Replace with the buyer's NFT token account
        delegate: delegateAddress.publicKey, // Replace with the delegate authority's public key
        tokenProgram: TOKEN_PROGRAM_ID,
        systemProgram: SystemProgram.programId,
        
      });

      console.log("AdminPayer",AdminPayer,AdminPayer.publicKey);
      transaction.feePayer = AdminPayer.publicKey
      // new PublicKey(Newbuyer);
      const { blockhash } = await connection.getLatestBlockhash();
      transaction.recentBlockhash = blockhash;
      console.log("blockhash-->", blockhash,);
       transaction.sign(delegateAddress,AdminPayer);
      // transaction.sign(delegateAddress);
      console.log("blockhash-->", blockhash, transaction,provider);
      const signed = await provider.signTransaction(transaction);
      console.log("signed-->", signed, connection);
      const signature = await connection.sendRawTransaction(signed.serialize());
      console.log("signature-->", signature);
      if (signature) {
        console.log(
          "TransactionHash",
          `https://explorer.solana.com/tx/${signature}?cluster=devnet`
        );
      }
      let signhash = await connection.confirmTransaction(signature);
      console.log("signhash-->", signhash);
      let status = await getTransactionstatus(signature);
      return { status };
    } catch (err) {
      console.log("buyNFT  err:", err);
    }
  };

 export const BidNFT = async (tokenAddress,mintAddress,totalAmount,decimal,bidderAddr,fee,provider) => {
  try{
  console.log("tokenAddress",tokenAddress,"mintAddress",mintAddress,"totalAmount",totalAmount,"decimal",decimal,fee);
    const delegateAddress = Keypair.generate();
    let string = arrayBufferToString(delegateAddress.secretKey.buffer);
    console.log("string", string);
    const bidder = bidderAddr;
    const token = tokenAddress;

    const nftaddress = new PublicKey(mintAddress)

    let accountInitialize = await createATA(
      bidder.toString(),
      nftaddress.toString(),
      bidder.toString(),
      provider
    )
    const tokenapprove = await tokenApprove(
      bidder.toString(),
      delegateAddress.publicKey.toString(),
      token.toString(), // mint Token Address
      Number(totalAmount) + (Number(totalAmount) * Number(fee) / 100), // amount of Token
      decimal, //token decimal,
      provider
    );
    let data = {
      ScrectKey: Encryptdata(string),
      tokenapprove:tokenapprove,
      status:true
    }
    return data;
  }catch(err){
    return {status:false,tokenApprove:{status:false}}
  }
  }


  export const buyNftWithToken = async (delegateKeypair,mintAddress,sellerAddress,Newbuyer,provider,Amount,decimals,NFTQuantity,MintPubKey,fee) => {
    console.log("buyNftWithToken",delegateKeypair,mintAddress,sellerAddress,Newbuyer,provider,Amount);
    const connection = new Connection(clusterApiUrl("devnet"), "confirmed");
    let program = await getProgramInstance(idl);
    const amount = new anchor.BN(Number(Amount) *  10 ** Number(decimals))
    const feeAmt = Number(Amount) * Number(fee) /100
    const totalprice = new anchor.BN((Number(Amount) + feeAmt) * 10 ** Number(decimals))
    console.log("🚀 ~ buyNftWithToken ~ totalprice:", totalprice)
    const count = new anchor.BN(Number(NFTQuantity) * 10 ** 0)
   // const delegateAddress = Decryptdata(delegateKeypair);
    const delegateKeypair1 = stringToArrayBuffer(delegateKeypair);
      const  delegateAddress = Keypair.fromSecretKey(Buffer.from(delegateKeypair1));
    const nftaddress = new PublicKey(mintAddress);
    const seller = new PublicKey(sellerAddress);
    const tokenaddress = new PublicKey(Config.erc20Address);
    const buyer = new PublicKey(Newbuyer);
    const sellerNftAccount = await getAssociatedTokenAddress(nftaddress, seller)
    console.log("sellerNftAccount", sellerNftAccount);
    const buyerNftAccount = await createATA(
      buyer.toString(),
      nftaddress.toString(),
      buyer.toString(),
      provider
    );
    console.log("buyerNftAccount", buyerNftAccount);
    const sellerTokenAccount = await getAssociatedTokenAddress(tokenaddress, seller)
    console.log("sellerTokenAccount", sellerTokenAccount);
    const buyerTokenAccount = await getAssociatedTokenAddress(tokenaddress, buyer)
    console.log("buyerTokenAccount", buyerTokenAccount);
    const tokenProgram = TOKEN_PROGRAM_ID
    const systemProgram = SystemProgram.programId

    const accountData = await program.account.accountData.fetch(idl.owner.address);
    console.log("🚀 ~ buyNFT ~ accountData:", accountData)
    const ownerPubkey =accountData.owner
   const ownerTokenAccount = await createATA(
    ownerPubkey.toString(),
    tokenaddress.toString(),
    buyer.toString(),
    provider
  );

  const metaData = await program.account.nftMetadata.fetch(MintPubKey)
  console.log("🚀 ~ buyNFT ~ metaData:", metaData)
  const creator = metaData.creator;
  const creatorTokenAccount = await createATA(
    creator.toString(),
    tokenaddress.toString(),
    buyer.toString(),
    provider
  );

    const AdminGas = stringToArrayBuffer(Config.PRIVATE);
    const  AdminPayer = Keypair.fromSecretKey(Buffer.from(AdminGas));
    const transaction = await program.methods.buyNftWithToken(amount,totalprice, count)
      .accounts({
        payer:AdminPayer.publicKey.toString(),
        buyer,
        seller,
        sellerNftAccount,
        buyerNftAccount,
        sellerTokenAccount,
        buyerTokenAccount,
        delegate: delegateAddress.publicKey,
        tokenProgram,
        systemProgram,
        mint : tokenaddress,
        ownerAccount : new PublicKey(idl.owner.address),
        ownerTokenAccount : ownerTokenAccount,
        metaAccount : MintPubKey,
        creatorTokenAccount : creatorTokenAccount
      })
      .transaction()

      console.log("AdminPayer",AdminPayer);
      transaction.feePayer = AdminPayer.publicKey
    //transaction.feePayer = provider.publicKey;
    const { blockhash } = await connection.getLatestBlockhash();
    transaction.recentBlockhash = blockhash;
    transaction.sign(delegateAddress,AdminPayer);
    console.log("blockhash-->", blockhash, transaction);
    const signed = await provider.signTransaction(transaction);
    console.log("signed-->", signed, connection);
    const signature = await connection.sendRawTransaction(signed.serialize());
    console.log("signature-->", signature);
    if (signature) {
      console.log(
        "TransactionHash",
        `https://explorer.solana.com/tx/${signature}?cluster=devnet`
      );
    }
    let signhash = await connection.confirmTransaction(signature);
    console.log("signhash-->", signhash);
    let status = await getTransactionstatus(signature);
    return { status };




  }

  export const AcceptBid = async (Details) => {
    try{
    console.log("Details AcceptBid", Details,Config.PRIVATE);
    const connection = new Connection(clusterApiUrl("devnet"), "confirmed");
    let program = await getProgramInstance(idl);
    const amount = new anchor.BN(Number(Details.Amount) *  10 ** Number(Details.decimal))
    const count = new anchor.BN(Number(Details.NoOfNft) *  10 ** 0)
    const TotAmt = Number(Details.Amount) * Number(Details.fee) /100
    const totalprice = new anchor.BN((Number(Details.Amount) + Number(TotAmt)) * 10 ** Number(Details.decimal))
    const delegateAddress1 =stringToArrayBuffer(Decryptdata(Details.delegateKeypair));
    const  delegateAddress = Keypair.fromSecretKey(Buffer.from(delegateAddress1));
    console.log("delegateAddress", delegateAddress);
    const nftaddress = new PublicKey(Details.mintAddress);
    const seller = new PublicKey(Details.SellAddr); // Details.SellAddr // sellerAddress;
    const tokenaddress = new PublicKey(Details.tokenAddress);
    const buyer = new PublicKey(Details.buyerAddress); //Details.buyerAddress;
    const sellerNftAccount = await getAssociatedTokenAddress(nftaddress, seller)
    console.log("sellerNftAccount",sellerNftAccount)
    const buyerNftAccount = await getAssociatedTokenAddress(nftaddress, buyer)
    const sellerTokenAccount = await getAssociatedTokenAddress(tokenaddress, seller)
   // await createATA(seller.toString(), nftaddress.toString(), seller.toString()) //await getAssociatedTokenAddress(tokenaddress, seller)
    const buyerTokenAccount = await getAssociatedTokenAddress(tokenaddress, buyer)
    //await createATA(buyer.toString(), nftaddress.toString(), seller.toString()) //await getAssociatedTokenAddress(tokenaddress, buyer)
    const tokenProgram = TOKEN_PROGRAM_ID
    const systemProgram = SystemProgram.programId

    const accountData = await program.account.accountData.fetch(idl.owner.address);
    console.log("🚀 ~ buyNFT ~ accountData:", accountData)
    const ownerPubkey =accountData.owner // new PublicKey("FgDaA4BaTtEh3QJ13mcNQWRZCPd6WGGwrErjyet25i2F") ;
    const ownerTokenAccount = await createATA(
     ownerPubkey.toString(),
     tokenaddress.toString(),
     seller.toString(),
     Details.provider
   );
 
 
    const metaData = await program.account.nftMetadata.fetch(Details.MintPubKey)
    console.log("🚀 ~ buyNFT ~ metaData:", metaData)
    const creator = metaData.creator;
    const creatorTokenAccount = await createATA(
     creator.toString(),
     tokenaddress.toString(),
     seller.toString(),
     Details.provider
   );

    const AdminGas = stringToArrayBuffer(Config.PRIVATE);
    const  AdminPayer = Keypair.fromSecretKey(Buffer.from(AdminGas));
    const transaction = await program.methods.acceptBid(amount,totalprice, count)
      .accounts({
        payer:AdminPayer.publicKey,
        buyer,
        seller,
        sellerNftAccount,
        buyerNftAccount,
        sellerTokenAccount,
        buyerTokenAccount,
        delegate: delegateAddress.publicKey,
        tokenProgram,
        systemProgram,
        mint : tokenaddress,
        ownerAccount : new PublicKey(idl.owner.address),
        ownerTokenAccount : ownerTokenAccount,
        metaAccount : Details.MintPubKey,
        creatorTokenAccount : creatorTokenAccount
      })
      .transaction()

      console.log("AdminPayer",AdminPayer);
      transaction.feePayer = AdminPayer.publicKey
    // transaction.feePayer = provider.publicKey;
    const { blockhash } = await connection.getLatestBlockhash();
    transaction.recentBlockhash = blockhash;
    transaction.sign(delegateAddress,AdminPayer);
    console.log("blockhash-->", blockhash, transaction);
    const signed = await provider.signTransaction(transaction);
    console.log("signed-->", signed, connection);
    const signature = await connection.sendRawTransaction(signed.serialize());
    console.log("signature-->", signature);
    if (signature) {
      console.log(
        "TransactionHash",
        `https://explorer.solana.com/tx/${signature}?cluster=devnet`
      );
    }
    let signhash = await connection.confirmTransaction(signature);
    console.log("signhash-->", signhash);
    let status = await getTransactionstatus(signature);
    console.log("status-->", status);
    return { status };
  } catch (error) {
    console.log("error-->", error);
    return { status: false };
  }
  }



  export const MintNFT = async (IPFS,Name,Royalty,Quantity,provider) => {
    const connection = new Connection(clusterApiUrl("devnet"), "confirmed");
    let program = await getProgramInstance(
      idl
    );
    const creator = provider.publicKey
    const payer = provider.publicKey;
    const count = new anchor.BN(Quantity)
    const royalty = new anchor.BN(Royalty * 100)
    const sellerfee = new anchor.BN(500)
    console.log("🚀 ~ constsol_git_mintfuct= ~ payer:", payer);
    const mintAccount = Keypair.generate();
    console.log("🚀 ~ constsol_git_mintfuct= ~ mintAccount:", mintAccount);

    const tokenMetadataProgram = new PublicKey(
      "metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s"
    );
    console.log(
      "🚀 ~ constsol_git_mintfuct= ~ tokenMetadataProgram:",
      tokenMetadataProgram
    );

    const metadata = {
      name: Name,
      symbol: "",
      uri: IPFS,
    };

    const associatedTokenAccount = getAssociatedTokenAddressSync(
      mintAccount.publicKey,
      creator
    );
    console.log(
      "🚀 ~ constsol_git_mintfuct= ~ associatedTokenAccount:",
      associatedTokenAccount
    );

    const metadataAccount = PublicKey.findProgramAddressSync(
      [
        Buffer.from("metadata"),
        tokenMetadataProgram.toBuffer(),
        mintAccount.publicKey.toBuffer(),
      ],
      tokenMetadataProgram
    )[0];
    console.log("🚀 ~ constsol_git_mintfuct= ~ metadataAccount:", metadataAccount)

    const editionAccount = PublicKey.findProgramAddressSync(
      [
        Buffer.from("metadata"),
        tokenMetadataProgram.toBuffer(),
        mintAccount.publicKey.toBuffer(),
        Buffer.from("edition"),
      ],
      tokenMetadataProgram
    )[0];

    const tokenProgram = TOKEN_PROGRAM_ID;
    const associatedTokenProgram = ASSOCIATED_TOKEN_PROGRAM_ID;
    const systemProgram = SystemProgram.programId;
    console.log("🚀 ~ constsol_git_mintfuct= ~ systemProgram:", systemProgram);
    const rent = new PublicKey("SysvarRent111111111111111111111111111111111");

    const newMetaAccount = Keypair.generate()
    console.log("🚀 ~ constsol_git_mintfuct= ~ newMetaAccount:", newMetaAccount.publicKey.toString())

    let accounts = {
      // creator : creator,
      nftMetadata: newMetaAccount.publicKey,
      payer: payer,
      metadataAccount,
      editionAccount,
      mintAccount: mintAccount.publicKey.toString(),
      associatedTokenAccount,
      tokenProgram,
      tokenMetadataProgram,
      associatedTokenProgram,
      systemProgram,
      rent,
    };

    let transaction = await program.methods
      .mintNft(
        metadata.name,
        metadata?.symbol,
        metadata.uri,
        count,
        royalty,
      )
      .accounts(accounts)
      .signers([mintAccount, newMetaAccount])
      .transaction();
    transaction.feePayer = payer;
    const { blockhash } = await connection.getLatestBlockhash();
    transaction.recentBlockhash = blockhash;
    transaction.sign(mintAccount, newMetaAccount);
    console.log("blockhash-->", blockhash, transaction);
    const signed = await provider.signTransaction(transaction);
    console.log("signed-->", signed, connection);
    const signature = await connection.sendRawTransaction(signed.serialize());
    console.log("signature-->", signature);
    if (signature) {
      console.log(
        "TransactionHash",
        `https://explorer.solana.com/tx/${signature}?cluster=devnet`
      );
    }
    let signhash = await connection.confirmTransaction(signature);
    console.log("signhash-->", signhash);
    let status = await getTransactionstatus(signature);
    console.log("status-->", status);
    const DataReturn = {
      HashValue : signature,
      CollectionSymbol : newMetaAccount.publicKey.toString(),
      tokenCounts : mintAccount.publicKey.toString()
    }
    return DataReturn;
  };


  export const nftDelegateApprove1 = async (Details) => {
    const delegateAddress = Keypair.generate();
    console.log(
      "🚀 ~ nftDelegateApprove ~ delegateAddress:",
      delegateAddress.publicKey.toString()
    );
    const mintAddress = new PublicKey(Details.NFTId);
    

    let string = arrayBufferToString(delegateAddress.secretKey.buffer);
    console.log("string", string);

    let arraybuffer = stringToArrayBuffer(string);
    console.log("arraybuffer:", arraybuffer);

    const seller = Details.NFTOwner;
    const nftMint = mintAddress;
    console.log("🚀 ~ nftDelegateApprove ~ nftMint:", nftMint);
    /* Token Approve */
    const tokenapprove = await tokenApprove(
      seller.toString(),
      delegateAddress.publicKey.toString(),
      nftMint.toString(), // mint Token Address
      1, // amount of Token,
      null,
      Details.provider
    );
    const Data ={
      ScrectKey: Encryptdata(string),
      tokenapprove:tokenapprove,
    }
    return Data;
  };